<template>
<!-- <div class="grid-y align-center content-grid no-overflow"> -->
  <div 
    class="thrifter swipeable-element" 
    :class="isInfo ? 'info-visible' : ''" 
    @touchstart="handleTouchStart" 
    @touchmove="handleTouchMove" 
    @touchend="handleTouchEnd" 
    @mousedown="handleMouseDown"
    @mousemove="handleMouseMove"
    @mouseup="handleMouseUp"
  >
  <!-- :style="{'background-image': 'url('+compThrifterData.img+')'}" -->
  <div class="background-wrapper"> 
    <div class="video-wrapper" :class="compThrifterData.videoVertical ? 'is-vertical':''">
      <video autoplay loop id="myVideo" class="video-background" data-no-fullscreen="true" ref="video" playsinline :muted="isMuted" :key="compThrifterData.id">
        <!-- <source src="https://www.w3schools.com/howto/rain.mp4" type="video/mp4" playsinline> -->
        <source :src="compThrifterData.video" type="video/mp4">
        <track label="Deutsch" kind="subtitles" srclang="de" :src="compThrifterData.caption" :key="compThrifterData.id+'caption'">
      </video>
    </div>
    <div class="info-wrapper">
      <div class="top">
        <ButtonClose @click.native="isInfo = false" />
      </div>
      <div class="info-content">
        <p class="title" v-html="compThrifterData.details.title"></p>
        <p v-html="compThrifterData.details.content"></p>
        <div v-for="(item,index) in compThrifterData.details.infoList" :key="index">
          <p class="subtitle" v-if="item.title!=null" v-html="item.title"></p>
          <p class="subtitle" v-if="item.title2!=null" v-html="item.title2"></p>
          <ul v-for="(list,listIndex) in item.list" :key="listIndex">
            <li v-html="list"></li>
          </ul>
        </div>
        <!-- <p class="subtitle">Daten &amp; Fakten</p>
        <ul>
          <li>Staatliche Förderung: Rürup-Förderung, Riester-Förderung, Erträge hälftig steuerfrei nach 12 Jahren ab dem 65. Lj.</li>
          <li>Sicherheit: Beitragsgarantie möglich</li>
          <li>Renditechancen: gering bis hoch, je nach Investment</li>
          <li>Wertschwankungen: möglich</li>
          <li>Verfügbarkeit: eingeschränkt, vorzeitige Entnahmen möglich</li>
          <li>Steuer: Einkünfte werden mit persönlichem Einkommensteuersatz besteuert</li>
        </ul> -->
        <div class="cta-btn" @click="contactInfoVisible = true">
          <span class="large">Hier kannst du dich beraten lassen</span>
        </div>
      </div>
    </div>
    <transition name="slide-in">
      <ContactView 
        v-if="contactInfoVisible" 
        :class="contactInfoVisible ? 'is-visible' : ''" 
        v-on:close-event="contactInfoVisible = false"
      />
    </transition>
    <div class="gui">
      <div class="top">
        <span class="hotness">{{compThrifterData.hotness}}% Matching</span>
        <!-- <ButtonBack @click.native="$router.push({name:'matching'}).catch(()=>{})"/> -->
          <ButtonClose @click.native="$router.push({name:'matching'}).catch(()=>{})" />
      </div>
      <div class="bottom-part">
        <div class="side-buttons">
          <div class="icon-wrapper" @click="isInfo = true">
            <i class="icon icn-info" />
          </div>
          <div class="icon-wrapper up" @click="toggleSound()">
            <i class="icon icn-sound" :class="isMuted ? 'muted' : ''" />
          </div>
          <!-- <div class="icon-wrapper">
            <i class="icon icn-subtitle" @click="toggleSubtitles()"/>
          </div> -->
          <div class="icon-wrapper"
             :class="($route.name === 'hotlist' ? 'active' : '')"
          >
            <i class="icon icn-hotlist" @click="$router.push({name:'hotlist'}).catch(()=>{})"/>
            <span class="amount2" v-if="state.hotNotList.hot.length > 0">{{state.hotNotList.hot.length}}</span>
          </div>
          <div class="icon-wrapper">
            <i class="icon icn-hot" 
              :class="compHotOrNot != null && compHotOrNot == 'hot' ? 'active' : '' "
              @click="$store.toggleHot($route.params.id)"/>
          </div>
        </div>
        <div class="text-wrapper" :class="subtitleVisible ? 'subtitles-visible':'' ">
          <!-- <p class="title">{{compThrifterData.title}}</p> -->
          <p class="title">{{compThrifterData.name}}{{compThrifterData.nameSuffix}}</p>
          <p class="subtitle">{{compThrifterData.subtitle}}</p>
          <!-- <p class="subtitle">{{compThrifterData.name}} @ {{compThrifterData.subtitle}}</p> -->
        </div>
        <!-- <div class="voting-buttons">
          <div 
            @click="setThrifterId(compPrevThrifter)"
            :class="compPrevThrifter > -1 ? '' : 'hidden' "
            class="btn-prev" 
          >
            <i class="icon icn-arrow-left" />
          </div>
          <div 
            class="btn-vote hot"
            :class="compHotOrNot != null && compHotOrNot == 'hot' ? 'opacity5' : '' "
            @click="$store.toggleHot($route.params.id)">
            <span>That's Hot</span>
            <lottie-animation 
                class="fire-anim"
                :animationData="require('@/assets/anim/fireWhite.json')"
                :loop="true"
                :autoplay="true"
                :speed="1"
            />
            <i class="icon icn-hot" />
          </div>
          <div 
            @click="setThrifterId(compNextThrifter)"
            :class="compNextThrifter > -1 ? '' : 'hidden' "
            class="btn-next"
          >
            <i class="icon icn-arrow-right" />
          </div>
        </div> -->

        <!-- <p v-if="$store.compTotalVotes < state.minThrifterOnHotlist" class="link-to-hotlist">Noch {{state.minThrifterOnHotlist-$store.compTotalVotes}} Vote bis zur Hotlist</p>
        <router-link 
          v-if="$store.compTotalVotes >= state.minThrifterOnHotlist"
          class="link-to-hotlist" 
          :class="$store.compTotalVotes < state.minThrifterOnHotlist ? 'hidden' : ''"
          :to="{name: 'hotlist'}"
        >Zu meiner Hotlist <span>&#8250;</span>
        </router-link> -->
        <div class="swipe-wrapper" :class="subtitleVisible ? 'subtitles-visible':'' ">
          <i class="icon icn-swipe" />
          <span>swipe zum nächsten Video</span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// import ButtonBack from '@/components/ButtonBack.vue'
import ButtonClose from '@/components/ButtonClose.vue'
import ContactView from '@/components/ContactView.vue'
// import LottieAnimation from "lottie-web-vue";

export default {
  name: 'Thrifter',
  components: {
    // ButtonBack,
    ButtonClose,
    ContactView,
    // LottieAnimation,
  },
  data() {
    return {
      state: this.$store.state,
      isInfo: false,
      isMuted: false,
      subtitleVisible: false,
      contactInfoVisible: false,
      startX: null,
      startY: null,
      currentX: null,
      currentY: null,
    }
  },
  computed: {
    compThrifterData() {
      return this.$store.matching.thrifterData.filter(obj => {
        return obj.id == this.$route.params.id;
      })[0]
    },
    compHotOrNot() {
      let hot = this.state.hotNotList.hot.includes(this.$route.params.id)
      let not = this.state.hotNotList.not.includes(this.$route.params.id)

      if (!hot) {
        if (!not) {
          return null
        }else {
          return "not"
        }
      }else {
        return "hot"
      }
    },
    compVideoElement() {
      return this.$refs.video
    },
    compCurrentThrifterPosition() {
      return this.$store.currentThrifterPositionInList(this.$route.params.id)
    },
    compPrevThrifter() {
      let _index = this.compCurrentThrifterPosition;
      let toReturn

      if (_index > 0) {
        toReturn = this.$store.getThrifterIdByindex(_index-1)
      }else {
        toReturn -1
      }
      
      return toReturn
    },
    compNextThrifter() {
      let _index = this.compCurrentThrifterPosition;
      let toReturn

      if (_index < this.$store.matching.thrifterData.length-1) {
        toReturn = this.$store.getThrifterIdByindex(_index+1)
      }else {
        toReturn -1
      }

      return toReturn
    },

  },
  mounted() {
  },
  methods: {
    canplayNow() {
      this.isMuted = false;
    },
    isPlaying() {
      setTimeout(() => {
        this.isMuted = false;
      }, 1000);
    },
    toggleSound() {
      this.isMuted = !this.isMuted
      // this.compVideoElement.muted = this.isMuted
    },
    toggleSubtitles() {
      this.subtitleVisible = !this.subtitleVisible
      if (this.subtitleVisible) {
        this.compVideoElement.textTracks[0].mode = "showing"
      }else {
        this.compVideoElement.textTracks[0].mode = "hidden"
      }
    },
    setThrifterId(_id) {
      // console.warn({_id});
      this.$router.replace({params:{id:_id}}).catch(()=>{})
    },
    swipeHandler(e) {
      // console.warn(e);
      if (e == 'left') {
        this.setThrifterId(this.compNextThrifter)
        // this.isMuted = true
      }else if (e == 'right') {
        this.setThrifterId(this.compPrevThrifter)
        // this.isMuted = true
      }
    },
    handleTouchStart(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    handleTouchMove(e) {
      this.currentX = e.touches[0].clientX;
      this.currentY = e.touches[0].clientY;
    },
    handleTouchEnd() {
      // console.warn({e});
      this.handleSwipe()
    },

    // Mouse Events (Fallback)
    handleMouseDown(e) {
      this.startX = e.clientX
      this.startY = e.clientY
    },
    handleMouseMove(e) {
      this.currentX = e.clientX
      this.currentY = e.clientY
    },
    handleMouseUp() {
      this.handleSwipe()
    },

    // Gemeinsame Swipe-Logik
    handleSwipe() {
      // Beispiel: Nur horizontal
      if (
        this.currentX &&
        Math.abs(this.currentX - this.startX) > 50 &&       // "Schwelle"
        Math.abs(this.currentY - this.startY) < 50
      ) {
        if (this.currentX > this.startX) {
          console.warn('swipe right')
          // Gehe zum vorherigen Video
          this.setThrifterId(this.compPrevThrifter)
        } else {
          console.warn('swipe left')
          // Gehe zum nächsten Video
          this.setThrifterId(this.compNextThrifter)
        }
      }
      // Reset
      this.startX = null
      this.startY = null
      this.currentX = null
      this.currentY = null
    }
  }
}
</script>

<style lang="stylus" scoped>

.swipeable-element {
  touch-action: pan-x;
}
.thrifter
  width 100vw
  height 100vh
  
  .background-wrapper
    background-repeat no-repeat
    background-size cover
    background-position center
    height 100%
    width 100%
  
  .video-wrapper
    position: fixed;
    min-height: 100vh;
    min-width: 100vw;
    top: 0;
    left: 0;

    .video-background
      display block
      position absolute
      height 100vh
      left 50%
      transform translateX(-50%)
      transition: all 0.3s ease-out;

  

  .info-wrapper 
    position absolute
    background-color #fff
    height 0
    bottom 0
    z-index 1
    transition: all 0.3s ease-out;
    overflow hidden
    box-shadow: 0px -3px 15px 0px rgb(0 0 0 / 85%);

    .info-content
      font-family "Hind"
      font-weight 500
      font-size 4.8vw
      line-height 6.4vw
      color #000
      padding 20vw 6.4vw
      text-align left

      p
        margin-bottom 8.53vw

      .title 
        font-weight 700
        font-size 7.47vw
        line-height 8.96vw

      .subtitle
        font-weight 700
        margin-bottom 2.13vw

    .top
      padding: 6.4vw 6.4vw 4.3vw;
      display: flex;
      justify-content: flex-end;
      position: fixed;
      width: 100vw;
      z-index: 9999;

  .gui
    .top
      // padding 15.1vw 4.3vw 0
      padding 7.88vh 4.3vw 0

      display flex
      justify-content space-between
      transition: all 0.3s ease-out;

      .hotness
        padding 2.1vw 4.3vw
        color #EE5859
        background-color #fff
        font-weight 700
        font-size 4.8vw
        line-height 6.4vw
        border-radius 2.13vw
        z-index 1

    .bottom-part
      position: fixed;
      bottom: 10.67vw;

      .swipe-wrapper 
        display flex
        justify-content center
        align-items center
        opacity 0.85
        filter drop-shadow(0px 0px 3px #000)

        &.subtitles-visible
          opacity: .1

        span
          font-size 3.2vw
          font-weight 600
          color #fff
          padding-left 2.68vw
          letter-spacing: 0.68vw

        // .icon-wrapper
        //   height 14.93vw
        //   width 14.93vw
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   transition: all 0.3s ease-out;
        //   filter: drop-shadow(0px 0px 5px #000);
          
        .icon
          display block
          // width 6.8vw
          // height 6.8vw
          height: 40px
          width: 40px
          background-color #fff
          mask-repeat: no-repeat

          &.icn-swipe
            // background-image url('~@/assets/icons/swipe.svg')
            mask-image: url('~@/assets/icons/swipe.svg')

      .side-buttons
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding 0 4.3vw
        margin-bottom 4.3vw
        // transform: translateY(-20px);

        .icon-wrapper
          position: relative
          // height 14.93vw
          // width 14.93vw
          width: 7.88vh
          height: 7.88vh
          display: flex;
          justify-content: flex-end;
          align-items: center;
          transition: all 0.3s ease-out;
          filter: drop-shadow(0px 0px 5px #000);
          cursor pointer
          
          .icon
            display block
            // width 6.8vw
            // height 6.8vw
            // width: 33px
            // height: 33px
            // height 73px
            height: 4.06vh;
            width: 4.06vh;
            // background-color #fff
            background-size: contain
            background-repeat: no-repeat
            mask-repeat: no-repeat

            &.icn-info
              background-image url('~@/assets/icons/info_new.svg')
              // mask-image: url('~@/assets/icons/info_new.svg')
            &.icn-sound
              background-image url('~@/assets/icons/sound_unmuted_new.svg')
              // mask-image: url('~@/assets/icons/sound_unmuted_new.svg')
              &.muted
                background-image url('~@/assets/icons/sound_muted_new.svg')
                // mask-image: url('~@/assets/icons/sound_muted_new.svg')
            &.icn-subtitle
              background-image url('~@/assets/icons/subtitle_new.svg')
              // mask-image: url('~@/assets/icons/subtitle_new.svg')
            &.icn-swipe
              background-image url('~@/assets/icons/swipe.svg')
              // mask-image: url('~@/assets/icons/swipe.svg')

            &.icn-hot
              // height 73px
              height: 9vh;
              background-image url('~@/assets/icons/btn_hot_inactive.svg')
              // mask-image: url('~@/assets/icons/btn_hot_inactive.svg')

              &.active
                background-image url('~@/assets/icons/btn_hot_active.svg')
                // mask-image: url('~@/assets/icons/btn_hot_active.svg')

            &.icn-hotlist
              background-image url('~@/assets/icons/hotlist_empty.svg')
              // mask-image: url('~@/assets/icons/hotlist_empty.svg')

              &.filled
                background-image url('~@/assets/icons/hotlist_filled.svg')
                // mask-image: url('~@/assets/icons/hotlist_filled.svg')

        .amount2
          position: absolute;
          right: 23%;
          bottom: 67%;
          background-color: #EE5859;
          border-radius: 100%;
          width: 15px;
          height: 15px;
          font-size: 0.75rem;
          font-weight: 900;
          color: #fff !important;

        &.active
          .icon
            background-color #EE5859
          
          span
            color #EE5859
            

      .text-wrapper 
        padding 0 4.3vw
        margin-bottom 4.3vw
        width 100vw
        color #fff

        &.subtitles-visible
          opacity: 0;//.1

        p
          margin 0

        .title
          font-weight 700
          font-size 7.36vw
          line-height 8.5vw

        .subtitle
          font-weight 700
          font-size 5vw
          line-height 6.4vw
          text-transform uppercase

      .voting-buttons
        padding 0 4.3vw
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom 4.3vw

        .icon
          display block
          width 5.33vw
          height 5.33vw
          background-color #fff

          &.icn-arrow-left
            mask-image: url('~@/assets/icons/chevron_right.svg')
            transform rotate(180deg)
          &.icn-arrow-right
            mask-image: url('~@/assets/icons/chevron_right.svg')
        
        .btn-vote
          // width: 23.47vw;
          height: 23.47vw;
          border-radius: 50vw;
          font-family: "Hind";
          font-weight: 600;
          font-size 5.33vw
          text-transform uppercase
          color: #fff;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding 0 8.533vw

          &.cold
            background-color: #3A428C;
          &.hot
            background-color: #EE5859;

          &.opacity5
            // opacity .5
            background-color rgba(238,88,89,.5)
            
            span
              color rgba(255,255,255,.5)

            .icon
              &.icn-hot
                display none
            
            .fire-anim
              // display block
              height auto
              width 40px
              margin-left 2vw
              transition: all 300ms cubic-bezier(0.000, 0.485, 0.000, 1.650);
              

            // .icon
            //   display block
            //   width 10.67vw
            //   height 10.67vw
            //   background-color #fff
            //   margin-left 2vw

            //   &.icn-hot
            //     display block
            //     mask-image: url('~@/assets/icons/hot.svg')

          span
            padding-top 1.1vw

          .fire-anim
            // display none
            height auto
            width 0
            margin-left 2vw

          .icon
            display none
            width 10.67vw
            height 10.67vw
            background-color #fff
            margin-left 2vw

            &.icn-hot
              // display block
              mask-image: url('~@/assets/icons/hot.svg')
            &.icn-arrow-left
              mask-image: url('~@/assets/icons/chevron_right.svg')
              transform rotate(180)
            &.icn-arrow-right
              mask-image: url('~@/assets/icons/chevron_right.svg')

      .link-to-hotlist
        font-family "Hind"
        font-weight 400
        font-size 4.3vw
        line-height 6vw
        color #fff

        &.hidden
          opacity 0

        span
          font-size 10vw
          vertical-align: sub;

  
  
  &.info-visible

    .video-background
      height 100vw !important
      // height: @css{max(100vh - 220px, 100vw);}
      transition: all 0.3s ease-out;

    .info-wrapper 
      height calc(100vh - 100vw)!important
      min-height: 220px;
      // height: @css{max(220px, 100vh - 100vw);}
      transition: all 0.3s ease-out;
      overflow scroll
      // min-height 50vh
    
    .gui
      .top
        transform translateY(-50vw)
        opacity 0
        transition: all 0.3s ease-out;

      
      .bottom-part
        .side-buttons
          .icon-wrapper
            opacity 0
            &.up
              transform translateY(-35vw)
              opacity 1
              transition: all 0.3s ease-out;

  .cta-btn
    display: flex;
    justify-content: center;
    margin-top: 4.8vw;
    cursor: pointer;

    span
      font-family "Hind"
      font-weight 600
      background-color #EE5859
      color #fff
      font-size 4.3vw
      line-height 6.5vw
      text-align center
      border-radius 100px
      padding 2.13vw 6.4vw

      &.hollow 
        background-color transparent
        color #EE5859
        border 2px solid #EE5859
      
      &.large 
        width 100%
      
.slide-in-enter-active {
  transition: all .3s ease;
}
.slide-in-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-in-enter, .slide-in-leave-to {
  transform: translateY(15vw);
  opacity: 0;
}

.hidden {
  opacity 0
  pointer-events none
}

// subtitles

video::cue {
  color #fff
  font-family "Hind"
  font-size 5vw
  opacity: 1
  transform: translateX(50%) translateY(-120px) scale(0.9);
}

// styling cursive text
// video::cue(i) {
//   color red;
// }

video::-webkit-media-text-track-container {
  // overflow visible !important
  // position absolute !important
  // top 50vh !important
  // transform: scale(.5) !important;
  // padding 40vw !important
}

video::-webkit-media-text-track-display 
  max-width: 50%!important;
  transform: translateX(50%) translateY(-120px) scale(0.9);
  line-height: 6.5vw;
  font-size 5vw!important

.is-vertical video::-webkit-media-text-track-display 
  max-width: 100%!important;
  transform: translateX(0%) translateY(calc(-80px - 10.67vw)) scale(0.9);
  line-height: 6.5vw;
  font-size 5vw!important

.info-visible 
  video::-webkit-media-text-track-display 
    max-width: 100%!important;
    transform: translateX(0) translateY(0) scale(0.9);
    line-height: 6.5vw;

</style>